import React, { Component } from "react"
import { graphql, useStaticQuery } from "gatsby"

import aboutStyles from "./about.module.css"
import { withTranslation } from "react-i18next"

import Presentation from "./presentationImg"

import Footer from "../footer"

import Comissions from "./showComissions"
import Exhibitions from "./showExhibitions"

class presImg extends Component {
  constructor(props) {
    super(props)
    this.state = {
      t: props.t,
      i18n: props.i18n,
      opacity: 1,
      openMore: false,
      openCom: false,
      openExhi: false,
      opening: false,
    }
    this.onClickArrow = this.onClickArrow.bind(this)
    this.changeMore = this.changeMore.bind(this)
    this.changeCom = this.changeCom.bind(this)
    this.changeExhi = this.changeExhi.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll)
  }

  onScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    var opcity = -(1 / 130) * winScroll + 1
    if (window.innerWidth < 650) {
      this.setState({ opacity: opcity })
    }
  }

  componentDidUpdate() {
    if (this.state.opening) {
      this.state.opening = false
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop

      window.scrollTo({
        top: winScroll + window.innerWidth / 3,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  onClickArrow = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    window.scrollTo({
      top: winScroll + 600,
      left: 0,
      behavior: "smooth",
    })
  }

  changeMore = () => {
    this.setState(prevState => ({
      openMore: !prevState.openMore,
      opening: true,
    }))
  }

  changeCom = () => {
    this.setState(prevState => ({
      openCom: !prevState.openCom,
      openExhi: false,
      opening: true,
    }))
  }

  changeExhi = () => {
    this.setState(prevState => ({
      openExhi: !prevState.openExhi,
      openCom: false,
      opening: true,
    }))
  }

  render() {
    return (
      <div>
        <div className={aboutStyles.FirstPhone}>
          <div className={aboutStyles.textFirst}>
            {this.state.t("about.texta.1")}
            <br></br>
            {this.state.t("about.texta.2")}
            <br></br>
            {this.state.t("about.texta.3")}
            <br></br>
            {this.state.t("about.texta.4")}
          </div>
        </div>
        <div className={aboutStyles.Mountain}>
          <img
            className={aboutStyles.LittleImgMountain}
            src="/static/3dbc0ed77ef87dd5d3d90144abcf5fd3/ee604/mountain_Liz_del_Mar.png"
          ></img>
        </div>
        <div className={aboutStyles.SecondPhone}>
          <div className={aboutStyles.textSecond}>
            &ensp; {this.state.t("about.textb.1")}
            <br></br>
            {this.state.t("about.textb.2")}
            <br></br>
            &ensp; {this.state.t("about.textb.3")}
          </div>
        </div>
        <div className={aboutStyles.Flower}>
          <img
            className={aboutStyles.LittleImgPlan}
            src="/static/eb8477f1fe05315936d3c9fd6afbb86f/ee604/plant_Liz_del_Mar.png"
          ></img>
        </div>
        <div className={aboutStyles.ThirdPhone}>
          <div className={aboutStyles.textThird}>
            {this.state.t("about.textc.1")}
            <br></br>
            {this.state.t("about.textc.2")}
            <br></br>
            &ensp;&ensp;{this.state.t("about.textc.3")}
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(presImg)
