import React from "react"

import containerStyles from "./commingSoon.module.css"

export default props => {
  return (
    <div className={containerStyles.container}>
      <h2>{props.text} page comming soon</h2>
      {/* <img src="http://images.ctfassets.net/55t3cch4urs2/GHNFVvKZ3np4gnwivUk3D/cc0a7997cbe8efacf1997a9558f9f9d0/corazoncito.png?w=300&fl=progressive&q=50"></img> */}
    </div>
  )
}
