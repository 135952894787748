import React, { Component } from "react"
import { graphql, useStaticQuery } from "gatsby"

import aboutStyles from "./about.module.css"
import { withTranslation } from "react-i18next"

import Presentation from "./presentationImg"
import PresentationVert from "./presentationImgVert"

import Footer from "../footer"

import Comissions from "./showComissions"
import Exhibitions from "./showExhibitions"

import VerticalPres from "./showPresPhone"

class presImg extends Component {
  constructor(props) {
    super(props)
    this.state = {
      t: props.t,
      i18n: props.i18n,
      opacity: 1,
      openMore: false,
      openCom: false,
      openExhi: false,
      opening: false,
    }
    this.onClickArrow = this.onClickArrow.bind(this)
    this.changeMore = this.changeMore.bind(this)
    this.changeCom = this.changeCom.bind(this)
    this.changeExhi = this.changeExhi.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll)
  }

  onScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    var opcity = -(1 / 130) * winScroll + 1
    if (window.innerWidth < 650) {
      this.setState({ opacity: opcity })
    }
  }

  componentDidUpdate() {
    if (this.state.opening) {
      this.state.opening = false
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop

      window.scrollTo({
        top: winScroll + window.innerWidth / 3,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  onClickArrow = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    // window.scrollTo(0, winScroll + 500)

    window.scrollTo({
      top: winScroll + 600,
      left: 0,
      behavior: "smooth",
    })
  }

  changeMore = () => {
    this.setState(prevState => ({
      openMore: !prevState.openMore,
      opening: true,
    }))
  }

  changeCom = () => {
    this.setState(prevState => ({
      openCom: !prevState.openCom,
      openExhi: false,
      opening: true,
    }))
  }

  changeExhi = () => {
    this.setState(prevState => ({
      openExhi: !prevState.openExhi,
      openCom: false,
      opening: true,
    }))
  }

  render() {
    var stilo = {
      opacity: this.state.opacity,
    }

    return (
      <div>
        <div className={aboutStyles.topPart}>
          <div className={aboutStyles.leftPart}>
            <div className={aboutStyles.preIn} id={aboutStyles.BigPres}>
              <Presentation changeMore={this.changeMore}></Presentation>
            </div>
            <div className={aboutStyles.preInV}>
              <PresentationVert changeMore={this.changeMore}></PresentationVert>
            </div>
          </div>
          <div className={aboutStyles.RightPart}>
            <div className={aboutStyles.preIn}>
              <div className={aboutStyles.aniContainer}>
                <img
                  className={aboutStyles.image}
                  src="/static/ceb38eabbf196c1bec0589e9dfc11660/ee604/aboutMe.png"
                ></img>
                <img
                  className={aboutStyles.gorrito}
                  src="/static/c882e0550a779bbbec2590efc5c7a685/ee604/gorrito.png"
                ></img>
              </div>
            </div>
          </div>
        </div>
        {this.state.openMore ? (
          <div>
            <div className={aboutStyles.BottomPart}>
              <div className={aboutStyles.leftPartBot}>
                <div className={aboutStyles.Comissions}>
                  <div className={aboutStyles.TitleComissions}>
                    Comissions and works
                  </div>
                  <div className={aboutStyles.textComissions}>
                    <Comissions></Comissions>
                  </div>
                </div>
              </div>
              <div className={aboutStyles.centerPartBot}>
                <div className={aboutStyles.LulitoContainer}>
                  <img
                    className={aboutStyles.DonLulito}
                    src="/static/f9a2557053390caaa02bfdcaa8755432/ee604/Donlulito_Liz_del_Mar.png"
                  ></img>
                </div>
              </div>
              <div className={aboutStyles.rightPartBot}>
                <div className={aboutStyles.Exhibitions}>
                  <div className={aboutStyles.TitleExhibitions}>
                    Exhibitions
                  </div>
                  <div className={aboutStyles.textExhibitions}>
                    <Exhibitions></Exhibitions>
                  </div>
                </div>
              </div>
            </div>
            <div className={aboutStyles.MaticaContainer}>
              <img
                className={aboutStyles.Matica}
                src="/static/e61b3a1b4360738258228fcc510c9eee/ee604/matica_Liz_del_Mar.png"
              ></img>
            </div>
            <div className={aboutStyles.footerAboutUp}>
              <Footer></Footer>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className={aboutStyles.phoneDescription}>
          <VerticalPres></VerticalPres>
          <div className={aboutStyles.Buttons}>
            <div className={aboutStyles.ButtonCom}>
              <div>Commisions and Works</div>
              <img
                onClick={this.changeCom}
                className={aboutStyles.PlusButPhone}
                src="/static/209e53748a5677b7a35725de7bc5e2ed/1035a/plus.png"
              ></img>
            </div>
            <div className={aboutStyles.ButtonExhi}>
              <div>Exhibitions</div>
              <img
                onClick={this.changeExhi}
                className={aboutStyles.PlusButPhone}
                src="/static/209e53748a5677b7a35725de7bc5e2ed/1035a/plus.png"
              ></img>
            </div>
          </div>
          {this.state.openExhi ? (
            <div>
              <div className={aboutStyles.Exhibitions}>
                <div className={aboutStyles.TitleExhibitions}>Exhibitions</div>
                <div className={aboutStyles.textExhibitions}>
                  <Exhibitions></Exhibitions>
                </div>
              </div>
              <div className={aboutStyles.footerAbout}>
                <Footer></Footer>
              </div>
            </div>
          ) : (
            <div />
          )}

          {this.state.openCom ? (
            <div>
              <div className={aboutStyles.Comissions}>
                <div className={aboutStyles.TitleComissions}>
                  Comissions and works
                </div>
                <div className={aboutStyles.textComissions}>
                  <Comissions></Comissions>
                </div>
              </div>
              <div className={aboutStyles.footerAbout}>
                <Footer></Footer>
              </div>
            </div>
          ) : (
            <div />
          )}

          <div style={stilo} onClick={this.onClickArrow}>
            <img
              className={aboutStyles.arrowDown}
              src="/static/b67bc1668f5161c0414d9ea821943be4/73f23/violet_arrow.png"
            ></img>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(presImg)
