import React from "react"
import { Link } from "gatsby"
import CommingSoon from "../components/commingSoon"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import About from "../components/aboutme/showPres"

const IndexPage = () => {
  return (
    <div>
      <SEO title="About" />
      <About></About>
    </div>
  )
}

export default IndexPage
