import React, { Component } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import ManStyles from "./manchita.module.css"

import { withTranslation } from "react-i18next"

const cl1 = {
  fill: "#FF6161",
}

const cl2 = {
  fill: "#FCB4B4",
  mixBlendMode: "multiply",
}

const cl3 = {
  fill: "#8D95FF",
  mixBlendMode: "multiply",
}

const text = {
  fill: "white",
  fontSize: "35px",
  fontFamily: "Lato",
  fontWeight: "400",
}

const textb = {
  fill: "white",
  fontSize: "35px",
  fontFamily: "Lato",
  fontWeight: "400",
}

const textc = {
  fill: "white",
  fontSize: "35px",
  fontFamily: "Lato",
  fontWeight: "400",
}

const textd = {
  fill: "white",
  fontSize: "35px",
  fontFamily: "Lato",
  fontWeight: "900",
}

class presImg extends Component {
  constructor(props) {
    super(props)
    this.state = {
      t: props.t,
      i18n: props.i18n,
    }

    this.texta = {
      space: 55,
      xPos: 180,
      yPos: 500,
    }

    this.textb = {
      space: 55,
      xPos: 190,
      yPos: 1300,
    }

    this.textc = {
      space: 55,
      xPos: 460,
      yPos: 960,
    }
  }

  render() {
    var transformeRed = {
      transform: "translate(-400 300) scale(0.93 , 0.9)",
    }

    var transformeRose = {
      transform: "translate(100 400) scale(1)",
    }

    var transformeBlue = {
      transform: "translate(-140 100) scale(0.9)",
    }

    return (
      <div className={ManStyles.Global}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1318.59 1676.98"
        >
          <g id="manchas">
            <g className={ManStyles.One}>
              <path
                {...transformeRed}
                class="st3"
                style={cl1}
                d="M981.24,650.02c-276.53,174.56-73.58,545.38,149.1,381.21c56.53-31.52,104.49-34.71,163.29-30.75
			c125.99,11.79,135.15,92.2,197.35,120.87c136.06,61.66,259.48,5.32,296.88-113.94c97.2-367.04-337.92-428-342.68-490.75
			c-107.03-166.07-291.22-149.68-345.92-72.24C1052.72,517.75,1114.08,551.89,981.24,650.02z"
              />
              <path
                {...transformeRed}
                class="st3"
                style={cl1}
                d="M1596.71,476.97c-4.8,46.94,43.15,20.59,61.88,65.74C1744.73,592.63,1637.39,399.55,1596.71,476.97z"
              />
              <path
                {...transformeRed}
                class="st3"
                style={cl1}
                d="M1245.48,1033.08c-50.06-5.35,6.95,42.95,20.49,32.11c10.22-10.18-21.3-16.14-13.09-26.52
			C1258.96,1033.67,1256.24,1023.77,1245.48,1033.08z"
              />
              <path
                {...transformeRed}
                class="st3"
                style={cl1}
                d="M1687.33,602.06c-1.79-20.6-27.91-18.45-28.52,0C1660.4,623,1686.45,620.22,1687.33,602.06z"
              />
              <path
                {...transformeRed}
                class="st3"
                style={cl1}
                d="M963.52,1055.83c-1.41-16.7-22.74-15.16-23.35,0C941.3,1072.62,962.68,1070.89,963.52,1055.83z"
              />

              <text style={textc}>
                <tspan x={this.textc.xPos} y={this.textc.yPos}>
                  {this.state.t("about.textc.1")}
                </tspan>
                <tspan
                  x={this.textc.xPos - 30}
                  y={this.textc.yPos + 1 * this.textc.space}
                >
                  {this.state.t("about.textc.2")}
                </tspan>
                <tspan
                  x={this.textc.xPos}
                  y={this.textc.yPos + 2 * this.textc.space}
                >
                  {this.state.t("about.textc.3")}
                </tspan>

                <tspan
                  style={textd}
                  x={this.textc.xPos + 0}
                  y={this.textc.yPos + 3.5 * this.textc.space}
                >
                  {this.state.t("about.textc.4")}
                </tspan>
              </text>
            </g>

            <g className={ManStyles.Two}>
              <path
                {...transformeRose}
                class="st1"
                style={cl2}
                d="M608.02,872.98c-47.45-37.56-25.91-79.61-33.03-111.39c-82.22-135.6-182.53-10.55-211.66-9.91
			c-143.22,10.31-210.16-75.56-307.26,71.99c-83.86,203.59,130.35,208.21-8.47,284.32c-14.31,12.68-20.29,29.21-13.95,53.34
			c73.92,123.61,223.94-80.96,323.61-24.14c111.26,66.97,171.11,110.81,282.27,33.56c104.16-97.29-8.56-122.75-2.24-173.68
			C687.77,900.03,682.8,893.57,608.02,872.98z"
              />
              <text style={textb}>
                <tspan x={this.textb.xPos} y={this.textb.yPos}>
                  {this.state.t("about.textb.1")}
                </tspan>
                <tspan
                  x={this.textb.xPos}
                  y={this.textb.yPos + 1 * this.textb.space}
                >
                  {this.state.t("about.textb.2")}
                </tspan>
                <tspan
                  x={this.textb.xPos}
                  y={this.textb.yPos + 2 * this.textb.space}
                >
                  {this.state.t("about.textb.3")}
                </tspan>
              </text>
            </g>
            <g className={ManStyles.Three}>
              <path
                {...transformeBlue}
                class="st6"
                style={cl3}
                d="M1026.97,347.27c126.93,90.76,226.27,43.07,193.33,235.93c-107.12,247.39-385.65,82.91-388.22,109.51
			c-19.84,5.46-34.74,26.84-47.21,52.47c-98.84,267.45-340.36,128.54-477.7,60.08c-188.53-98.15-138.82-299.9-33.21-392.85
			c75.3-70.35,130.63-61.97,165.15-111.27C591.36-37.6,957.11,63.81,1026.97,347.27z"
              />
              <path
                {...transformeBlue}
                class="st6"
                style={cl3}
                d="M330.41,316.7c75.06,51.8,77.61-137.02,70.58-135.57C363.36,177.92,272.96,291.53,330.41,316.7z"
              />
              <path
                {...transformeBlue}
                class="st6"
                style={cl3}
                d="M986.02,192.75c-3.16,17.84,34.09,31.9,34.38,62.83C1080.08,343.75,1023.12,131.88,986.02,192.75z"
              />
              <path
                {...transformeBlue}
                class="st6"
                style={cl3}
                d="M1060.8,324.9c-22-9.02-28.5,19.34-10.41,26.74C1072.14,358.46,1073.79,329.61,1060.8,324.9z"
              />

              <text style={text}>
                <tspan x={this.texta.xPos} y={this.texta.yPos}>
                  {this.state.t("about.texta.1")}
                </tspan>
                <tspan
                  x={this.texta.xPos}
                  y={this.texta.yPos + 1 * this.texta.space}
                >
                  {this.state.t("about.texta.2")}
                </tspan>
                <tspan
                  x={this.texta.xPos}
                  y={this.texta.yPos + 2 * this.texta.space}
                >
                  {this.state.t("about.texta.3")}
                </tspan>
                <tspan
                  x={this.texta.xPos}
                  y={this.texta.yPos + 3 * this.texta.space}
                >
                  {this.state.t("about.texta.4")}
                </tspan>
              </text>
            </g>
          </g>
        </svg>
        <img
          {...transformeRed}
          onClick={this.props.changeMore}
          className={ManStyles.Plus}
          src="/static/209e53748a5677b7a35725de7bc5e2ed/1035a/plus.png"
        ></img>
      </div>
    )
  }
}

export default withTranslation()(presImg)
