import React, { Component } from "react"
import dataStyles from "./data.module.css"
import { withTranslation } from "react-i18next"

import CommissionsData from "../../data/commisions.json"

class ItemCom extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }))
  }

  render() {
    return (
      <div onClick={this.handleClick} className={dataStyles.ItemDat}>
        <div className={dataStyles.TitleDat}>
          {this.props.itemData.title}, {this.props.itemData.type}
        </div>
        {this.state.open ? (
          <div className={dataStyles.SubDat}>
            {this.props.itemData.employer}
          </div>
        ) : (
          <div />
        )}
      </div>
    )
  }
}

class YearData extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    var allData = []

    for (var key in this.props.dat) {
      allData.push(<ItemCom itemData={this.props.dat[key]}></ItemCom>)
    }

    return <div>{allData}</div>
  }
}

class comissions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      t: props.t,
      i18n: props.i18n,
    }
  }

  render() {
    var data = CommissionsData
    data = data[this.state.i18n.languages[0]]
    var yearData = {}
    for (let i = 0; i < data.length; i++) {
      if (!(data[i].year in yearData)) {
        yearData[data[i].year] = []
      }
      yearData[data[i].year].push(data[i])
    }

    data = []

    for (var key in yearData) {
      data.unshift(
        <div className={dataStyles.Year}>
          {key}
          <YearData dat={yearData[key]}></YearData>
        </div>
      )
    }

    return <div>{data}</div>
  }
}

export default withTranslation()(comissions)
